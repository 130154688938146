module.exports = {
  locales: ['default', 'ja'],
  defaultLocale: 'default',
  pages: {
    '*': ['common', 'error'],
    'rgx:^/\\[organization\\]/\\[portal\\]/admin': ['adminCommon'],
    'rgx:^/\\[organization\\]/settings': ['adminCommon'],
    '/login': ['login'],
    '/vb-login': ['login'],
    '/signup': ['signup'],
    '/password-resets/[resetToken]': ['passwordResets'],
    '/password-resets/send-email': ['passwordResetSendEmail'],
    '/redirect': ['redirect'],
    '/system/announcements': ['announcement'],
    '/[organization]': ['masterPortal'],
    '/[organization]/[portal]': ['top'],
    '/[organization]/[portal]/admin/article': [
      'adminArticleList',
      'adminArticleEdit',
      'adminArticleVideoBrainEmbedContent',
    ],
    '/[organization]/[portal]/admin/article/new': ['adminArticleEdit', 'adminArticleVideoBrainEmbedContent'],
    '/[organization]/[portal]/admin/article/[id]': ['adminArticleEdit', 'adminArticleVideoBrainEmbedContent'],
    '/[organization]/[portal]/admin/article/sort': ['adminArticleSort'],
    '/[organization]/[portal]/admin/category': ['adminCategory'],
    '/[organization]/[portal]/admin/courses': ['adminCourse'],
    '/[organization]/[portal]/admin/courses/new': ['adminCourseEdit', 'adminExam'],
    '/[organization]/[portal]/admin/courses/[id]/edit': ['adminCourseEdit', 'adminExam'],
    '/[organization]/[portal]/admin/design': ['adminDesign'],
    '/[organization]/[portal]/admin/exams': ['adminExam'],
    '/[organization]/[portal]/admin/exams/new': ['adminExamEdit'],
    '/[organization]/[portal]/admin/exams/[id]': ['adminExamEdit'],
    '/[organization]/[portal]/admin/insight/article': ['adminArticleAnalysisList'],
    '/[organization]/[portal]/admin/insight/article/[id]': ['adminArticleInsightDetailList'],
    '/[organization]/[portal]/admin/insight/courses': ['adminCourseInsight'],
    '/[organization]/[portal]/admin/insight/courses/[courseId]': ['adminCourseInsightDetail'],
    '/[organization]/[portal]/admin/insight/courses/[courseId]/users/[id]': ['adminCourseInsight'],
    '/[organization]/[portal]/admin/insight/download': ['adminInsightDownload'],
    '/[organization]/[portal]/admin/insight/exams': ['adminExamInsight'],
    '/[organization]/[portal]/admin/insight/exams/[id]': ['adminExamInsightDetail'],
    '/[organization]/[portal]/admin/insight/exams/[id]/users/[userId]': [
      'adminExamInsightByUser',
      'adminExamInsightDetail',
    ],
    '/[organization]/[portal]/admin/insight/surveys': ['adminSurveyInsight'],
    '/[organization]/[portal]/admin/insight/user': ['adminUserAnalysisList'],
    '/[organization]/[portal]/admin/insight/user/[id]': ['adminUserAnalysis', 'adminExamInsightDetail'],
    '/[organization]/[portal]/admin/language': ['adminLanguage'],
    '/[organization]/[portal]/admin/surveys': ['adminSurvey'],
    '/[organization]/[portal]/admin/surveys/new': ['adminSurveyEdit'],
    '/[organization]/[portal]/admin/surveys/[id]': ['adminSurveyEdit'],
    '/[organization]/[portal]/admin/tag': ['adminTag'],
    '/[organization]/[portal]/admin/tasks': ['adminTask'],
    '/[organization]/[portal]/admin/tasks/new': ['adminTaskEdit', 'adminCourse'],
    '/[organization]/[portal]/admin/tasks/[id]': ['adminTaskDetail'],
    '/[organization]/[portal]/admin/tasks/[id]/edit': ['adminTaskEdit', 'adminCourse'],
    '/[organization]/[portal]/admin/tasks/[id]/users/[userId]': [
      'adminCourseInsight',
      'adminTaskEdit',
      'adminTaskDetail',
      'adminTaskInsight',
    ],
    '/[organization]/[portal]/admin/user-list': ['adminUserList'],
    '/[organization]/[portal]/admin/user-list/new': ['adminUserListNew'],
    '/[organization]/[portal]/categories/[id]': ['category'],
    '/[organization]/[portal]/categories/[id]/articles': ['category'],
    '/[organization]/[portal]/courses': ['course'],
    '/[organization]/[portal]/courses/[courseId]': ['course'],
    '/[organization]/[portal]/courses/[courseId]/course-items/[id]': ['exam'],
    '/[organization]/[portal]/courses/[courseId]/course-items/[id]/exam-results/[examResultId]': ['exam'],
    '/[organization]/[portal]/exams/[examId]': ['exam'],
    '/[organization]/[portal]/exams/[examId]/results/[id]': ['exam'],
    '/[organization]/[portal]/history': ['history'],
    '/[organization]/[portal]/mylist': ['myList'],
    '/[organization]/[portal]/search': ['search'],
    '/[organization]/[portal]/surveys/[id]': ['survey'],
    '/[organization]/[portal]/tags/[id]': ['tag'],
    '/[organization]/settings/contract': ['contract'],
    '/[organization]/settings/usage-summary': ['usageSummary'],
    '/[organization]/settings/user-info': ['userInfo'],
    '/[organization]/settings/users': ['organizationUsers'],
    '/[organization]/settings/users/invitation': ['userInvitation'],
    '/[organization]/settings/user-groups': ['userGroup', 'userGroupList'],
    '/[organization]/settings/user-groups/[id]': ['userGroup', 'userGroupDetail'],
  },
  defaultNS: 'common',
  loadLocaleFrom: (lang, ns) => import(`./src/locales/${lang}/${ns}.json`).then(m => m.default),
};
